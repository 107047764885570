import React, { ReactNode, Suspense, useRef } from 'react';
import useFirstViewportEntry from '../../hooks/useFirstViewportEntry';

interface RenderOnViewportEntryProps {
  children: ReactNode;
  threshold?: number;
  root?: Element | null;
  rootMargin?: string;
  priority: string;
  style?: React.CSSProperties | undefined;
}

const RenderOnViewportEntry: React.FC<RenderOnViewportEntryProps> = ({
  children,
  threshold = 0, // default for Intersection Observer
  root = null, // default for Intersection Observer
  rootMargin = '0px 0px 0px 0px', // default for Intersection Observer,
  priority = 0,
  style,
  ...wrapperDivProps // For the div rendered below
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin });

  return (
    <div {...wrapperDivProps} ref={ref} style={style}>
      {entered ? (
        <Suspense fallback={<p>Hello world</p>}>{children}</Suspense>
      ) : null}
    </div>
  );
};

export default RenderOnViewportEntry;
