import { createSlice } from '@reduxjs/toolkit';

type intialStateType = {
  list: [];
};

export const homeInitialState: intialStateType = {
  list: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState: homeInitialState,
  reducers: {
    usedCarsList: (state, action) => {
      return { ...state, list: action.payload };
    },
  },
});

export const { usedCarsList } = homeSlice.actions;

export default homeSlice.reducer;
