import { useRouter } from 'next/router';
import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GlobalService } from '../helpers/services';
import { CommonUtils } from '../helpers/utilities';
import { City } from '../types/models';

const Context = createContext<{
  cities: Array<City>;
  selectCity: any;
  setSelectCity: (value: SetStateAction<any>) => void;
}>({
  cities: [],
  selectCity: {},
  setSelectCity(value) {},
});

export function CityProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const [selectCity, setSelectCity] = useState<any>({});
  const [cities, setCities] = useState<any>([]);

  useEffect(() => {
    const worker = new Worker('/worker.js');
    worker.postMessage({
      type: 'getAllCities',
      args: {
        baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
        currentLocale: router.locale,
      },
    });

    // Listen for messages from the worker
    worker.addEventListener('message', (event) => {
      setCities(event.data);
      const citiesData = event.data;
      if (typeof window !== 'undefined' && citiesData) {
        const city: any = localStorage.getItem('city');
        let cityParse: any = null;
        if (city || city !== 'undefined') cityParse = JSON.parse(city);
        if (!cityParse) {
          setSelectCity(citiesData[0]);
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                try {
                  let cityRes = await GlobalService.getAutoDetectCity(
                    CommonUtils.getLanguageId(router.locale!),
                    position.coords.latitude,
                    position.coords.longitude
                  );
                  const detectedCity = citiesData.find(
                    (item: any) => item.City === cityRes
                  );
                  if (detectedCity) {
                    if (typeof detectedCity === 'number') {
                      let tempSelectedCity = citiesData.find(
                        (item: any) => item.CityId === detectedCity
                      );
                      if (!tempSelectedCity) {
                        return;
                      }
                      setSelectCity(tempSelectedCity);
                    } else {
                      setSelectCity(detectedCity);
                    }
                  } else {
                    localStorage.setItem('city', JSON.stringify(citiesData[0]));
                    setSelectCity(citiesData[0]);
                  }
                } catch (err) {
                  localStorage.setItem('city', JSON.stringify(citiesData[0]));
                  setSelectCity(citiesData[0]);
                }
              },
              (error) => {
                localStorage.setItem('city', JSON.stringify(citiesData[0]));
                setSelectCity(citiesData[0]);
              }
            );
          }
        } else {
          if (citiesData && citiesData.length) {
            const detectedCity = citiesData.find(
              (item: any) => item.CityId === cityParse.CityId
            );

            localStorage.setItem('city', JSON.stringify(detectedCity));
            setSelectCity(detectedCity);
          }
        }
      }
    });

    return () => {
      worker.terminate();
    };
  }, [router.locale]);

  return (
    <Context.Provider
      value={{
        cities,
        selectCity,
        setSelectCity,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useCityContext() {
  return useContext(Context);
}
