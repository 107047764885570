import React, { createContext, useContext, useEffect } from 'react';
import MoEngage from '@moengage/web-sdk';
import IdentifierService from '../helpers/services/identifier.service';

const MoEngageContext = createContext(MoEngage);

const moeCluster = process.env.NEXT_PUBLIC_MOENGAGE_CLUSTER!;
const moeAppId = process.env.NEXT_PUBLIC_MOENGAGE_APP_ID!;
const moeDebugLogs =
  process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS === 'true' ? 1 : 0;

interface MoEngageProviderProps {
  children: React.ReactNode;
}

export const MoEngageProvider: React.FC<MoEngageProviderProps> = ({
  children,
}) => {
  useEffect(() => {
    const initMoEngage = async () => {
      MoEngage.initialize({
        app_id: moeAppId,
        cluster: moeCluster,
        debugLogs: moeDebugLogs,
      });
      // const clarityUserId = await IdentifierService.getClarityUserId();
      const GAID = await IdentifierService.getGAId();
      MoEngage.add_unique_user_id(GAID || '');
    };
    initMoEngage();
  }, []);

  return (
    <MoEngageContext.Provider value={MoEngage}>
      {children}
    </MoEngageContext.Provider>
  );
};

export const useMoEngage = () => {
  return useContext(MoEngageContext);
};
