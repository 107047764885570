import { SelfListingData } from '../../lib/self-listing/selfListing.slice';
import { otherVariantValue } from '../../types/constants';
import { SelfListingConstants } from '../../types/i18n.labels';
import { CookieUtils } from './cookie.utils';
import { SessionUtils } from './session.utils';

enum SelfListingLSKeys {
  SelfListingData = 'selfListingData',
  SelfListingCurrentStep = 'selfListingCurrentStep',
}

export class SelfListingUtils {
  /**
   * This utility method is used to get next step user needs to go at for Continue listing.
   * @returns SelfListingStep
   */
  static getNextStepFromCurrentStep = (
    step: string
  ): SelfListingConstants | undefined => {
    const finalStep = step as SelfListingConstants;
    switch (finalStep) {
      case SelfListingConstants.SelfListingSelectBrand:
        return SelfListingConstants.SelfListingSelectManufactureYear;
      case SelfListingConstants.SelfListingSelectManufactureYear:
        return SelfListingConstants.SelfListingSelectModel;
      case SelfListingConstants.SelfListingSelectModel:
        return SelfListingConstants.SelfListingSelectVariant;
      case SelfListingConstants.SelfListingSelectVariant:
        return SelfListingConstants.SelfListingSelectCity;
      case SelfListingConstants.SelfListingSelectCity:
        const isAuthenticated = SessionUtils.isValidSession();
        if (isAuthenticated) {
          return SelfListingConstants.SelfListingEnterKmDriven;
        } else {
          return SelfListingConstants.SelfListingAuthentication;
        }
      case SelfListingConstants.SelfListingAuthentication:
        return SelfListingConstants.SelfListingEnterKmDriven;
      case SelfListingConstants.SelfListingEnterKmDriven:
        return SelfListingConstants.SelfListingUploadCarImages;
      case SelfListingConstants.SelfListingUploadCarImages:
        return SelfListingConstants.SelfListingEnterExpectedPrice;
      default:
        return undefined;
    }
  };

  /**
   * This utility method is used to get the vehicle name from the current step and data.
   * @param step SelfListingConstants
   * @param data SelfListingData
   * @returns string
   */
  static getVehicleNameFromStepAndData = (
    step: SelfListingConstants | undefined,
    data: SelfListingData | null
  ) => {
    if (!step || !data) {
      return '';
    }
    console.log({ step, data });
    switch (step) {
      case SelfListingConstants.SelfListingSelectBrand:
        return `${data.brand.brandName}`;

      case SelfListingConstants.SelfListingSelectManufactureYear:
        return `${data.manufacture_year.year} ${data.brand.brandName}`;

      case SelfListingConstants.SelfListingSelectModel:
        return `${data.manufacture_year.year} ${data.brand.brandName} ${data.model.modelName}`;

      case SelfListingConstants.SelfListingSelectVariant:
        return `${data.manufacture_year.year} ${data.brand.brandName} ${
          data.model.modelName
        } ${
          data.variant.variantId === otherVariantValue
            ? ''
            : data.variant.variantName
        }`;

      default:
        if (
          data.brand.brandId &&
          data.manufacture_year.yearCode &&
          data.model.modelId
        ) {
          return `${data.manufacture_year.year} ${data.brand.brandName} ${
            data.model.modelName
          } ${
            data.variant.variantId === otherVariantValue
              ? ''
              : data.variant.variantName
          }`;
        } else {
          return '';
        }
    }
  };

  /**
   * This utility method is used to save the self listing data in cookies
   * @param data SelfListingData
   */
  static saveSelfListingDataToCookies = (data: SelfListingData) => {
    CookieUtils.setPublicCookie(
      SelfListingLSKeys.SelfListingData,
      JSON.stringify(data)
    );
  };

  /**
   * This utility method is used to get the self listing data from cookies.
   * @returns SelfListingData | null
   */
  static getSelfListingDataFromCookies = (): SelfListingData | null => {
    const data = CookieUtils.getPublicCookie(SelfListingLSKeys.SelfListingData);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  /**
   * This utility method is used to save the current step of the self listing flow in cookies.
   * @param step SelfListingConstants
   */
  static setCurrentStepToCookies = (step: SelfListingConstants) => {
    CookieUtils.setPublicCookie(
      SelfListingLSKeys.SelfListingCurrentStep,
      step.toString()
    );
  };

  /**
   * This utility method is used to get the current step of the self listing flow from cookies.
   * @returns SelfListingConstants | null
   */
  static getCurrentStepFromCookies = (): SelfListingConstants | null => {
    const step = CookieUtils.getPublicCookie(
      SelfListingLSKeys.SelfListingCurrentStep
    );
    if (step) {
      return step as SelfListingConstants;
    }
    return null;
  };

  /**
   * This utility method is used to delete the self listing data from cookies.
   */
  static deleteSelfListingDataFromCookies = () => {
    CookieUtils.deletePublicCookie(SelfListingLSKeys.SelfListingData);
  };

  /**
   * This utility method is used to delete the current step of the self listing flow from cookies.
   */
  static deleteCurrentStepFromCookies = () => {
    CookieUtils.deletePublicCookie(SelfListingLSKeys.SelfListingCurrentStep);
  };
}
