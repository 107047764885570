import { MouseEventHandler, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GlobalService, VehicleService } from '../../helpers/services';
import { City } from '../../types/models';
import { FadeUp } from '../common/Animations';
import FormInputV1 from '../common/Form/FormInputV1';
import { SearchIcon } from '../icons';
import Image from 'next/image';
import { CommonUtils } from '../../helpers/utilities';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from '../common/Modal';
import List from './List';
import { ClearSearchIcon } from './ClearSearchIcon';
import SearchResultsNotFound from './SearchResultsNotFound';
import { useTranslationContext } from '../../provider/TranslationProvider';
import { AnalyticsSource, Locales } from '../../types/enums';
import AnalyticsService from '../../helpers/services/analytics.service';
import { SearchUtils } from '../../helpers/utilities/search.utils';

type ModalProps = {
  cities: Array<City>;
  show: boolean;
  onClose?: any;
  selectCity?: any;
};

const options = {
  keys: ['City'],
  threshold: 0.4,
  distance: 70,
  minMatchCharLength: 2,
};

const SelectCity = ({ cities, show, onClose }: ModalProps) => {
  const { control, watch, setValue } = useForm();
  const router = useRouter();
  const [filteredCities, setFilteredCities] = useState<Array<City>>([]);
  const { LabelConstants } = useTranslationContext();

  const handleCitySelection = async (selectedCity: City | number) => {
    if (typeof selectedCity === 'number') {
      let tempSelectedCity = cities.find(
        (item) => item.CityId === selectedCity
      );

      if (!tempSelectedCity) {
        return;
      }
      onClose(tempSelectedCity);
    } else {
      onClose(selectedCity);
    }
  };

  const { searchCity } = watch();

  useEffect(() => {
    if (searchCity?.length) {
      const fuzzyResp = SearchUtils.fuzzySearch(searchCity, cities, options);
      setFilteredCities(fuzzyResp);
    }
  }, [cities, searchCity]);

  useEffect(() => {
    async function sendAnalytics() {
      await AnalyticsService.createStrapiAnalytics(
        'home_city_selection_page',
        AnalyticsSource.Home
      );
    }
    sendAnalytics();
  }, []);

  const handleGetCurrentLocation: MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        try {
          AnalyticsService.createStrapiAnalytics(
            'home_detect_my_city_clicked',
            AnalyticsSource.Home
          );
          let cityRes = await GlobalService.getAutoDetectCity(
            CommonUtils.getLanguageId(router.locale!),
            position.coords.latitude,
            position.coords.longitude
          );
          AnalyticsService.createStrapiAnalytics(
            'home_city_detected',
            AnalyticsSource.Home,
            JSON.stringify({
              result: cityRes,
            })
          );
          const detectedCity = cities.find((item) => item.City === cityRes);
          if (detectedCity) {
            AnalyticsService.createStrapiAnalytics(
              'home_city_found',
              AnalyticsSource.Home,
              JSON.stringify({
                detectedCity: detectedCity.City,
              })
            );
            handleCitySelection(detectedCity);
          } else {
            toast.error(LabelConstants?.NO_CITY, {
              position:
                router.locale === Locales.EN ? 'bottom-right' : 'bottom-left',
            });
            AnalyticsService.createStrapiAnalytics(
              'home_city_not_found',
              AnalyticsSource.Home
            );
          }
        } catch (error) {
          toast.error(LabelConstants?.SOMETHING_WENT_WRONG_ERROR, {
            position:
              router.locale === Locales.EN ? 'bottom-right' : 'bottom-left',
          });
        }
      });
    }
  };
  const closeModel = () => {
    onClose();
  };

  return (
    <>
      <Modal
        // backdrop="static"
        show={show}
        onClose={() => closeModel()}
        modalClassName="!pt-0"
        containerClassName="w-full md:!w-[400px] scrollbar-v1 !px-0"
      >
        <>
          <ModalHeader>
            <div className="font-medium text-xl text-primary-200">
              {LabelConstants?.SELECT_CITY}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col h-[90vh] md:h-[460px]">
              <FadeUp duration={0.5 * 1.5}>
                <div>
                  <FormInputV1
                    control={control}
                    className="search-city"
                    name="searchCity"
                    label={LabelConstants?.SEARCH_CITY}
                    placeholder={LabelConstants?.TYPE_CITY_NAME}
                    endIcon={
                      searchCity?.length ? (
                        <ClearSearchIcon
                          onClick={(e) => {
                            e.preventDefault();
                            setValue('searchCity', '');
                          }}
                        />
                      ) : (
                        <SearchIcon className="h-[24px] w-[24px] !text-[#484E50] p-[3px] opacity-[60%]" />
                      )
                    }
                  />
                </div>
                <div className="w-full">
                  <button
                    onClick={handleGetCurrentLocation}
                    className="w-full py-3 flex items-center gap-[9px] text-primary text-[15px]"
                    id="locationid"
                    aria-label="location"
                  >
                    <svg
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.83331 11.1074H4.58331"
                        stroke="#4C0055"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.4167 11.1074H20.1667"
                        stroke="#4C0055"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 1.94067V4.69067"
                        stroke="#4C0055"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 17.5242V20.2742"
                        stroke="#4C0055"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 17.524C14.5438 17.524 17.4166 14.6512 17.4166 11.1073C17.4166 7.56351 14.5438 4.69067 11 4.69067C7.45615 4.69067 4.58331 7.56351 4.58331 11.1073C4.58331 14.6512 7.45615 17.524 11 17.524Z"
                        stroke="#4C0055"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 13.8574C12.5188 13.8574 13.75 12.6262 13.75 11.1074C13.75 9.58864 12.5188 8.35742 11 8.35742C9.48122 8.35742 8.25 9.58864 8.25 11.1074C8.25 12.6262 9.48122 13.8574 11 13.8574Z"
                        stroke="#4C0055"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {LabelConstants?.USE_MY_CURRENT_LOCATION}
                  </button>
                </div>
              </FadeUp>

              {!searchCity || (searchCity && filteredCities.length > 0) ? (
                <div className="overflow-y-auto mt-[10px] mb-[24px]">
                  {!searchCity && (
                    <div className="mt-[10px] grid grid-cols-3 gap-2">
                      {cities.slice(0, 6).map((eachCity, index) => (
                        <div
                          key={eachCity.CityId}
                          onClick={() => handleCitySelection(eachCity)}
                        >
                          <CityImage city={eachCity} />
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="mt-[12px] overflow-x-hidden">
                    {!searchCity && (
                      <p className="text-[#757575] mt-[12px] text-[13px] ">
                        {LabelConstants?.ALL_CITIES}
                      </p>
                    )}
                    <List
                      itemsClasses="text-[15px] text-[#000000] py-[20px] border-b border-[#E0E0E0] "
                      items={(searchCity
                        ? filteredCities
                        : [...cities].sort((a, b) =>
                            a?.City?.toLowerCase()?.localeCompare(
                              b?.City?.toLowerCase()
                            )
                          )
                      ).map((eachCity) => ({
                        label: eachCity.City,
                        value: eachCity.CityId,
                      }))}
                      onClick={(val) =>
                        handleCitySelection(
                          typeof val.value === 'number'
                            ? val.value
                            : parseInt(val.value)
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <SearchResultsNotFound />
                </div>
              )}
            </div>
          </ModalBody>
        </>
      </Modal>
    </>
  );
};

export default SelectCity;

export const CityImage = ({ city }: { city: City }) => {
  return (
    <div className="w-[94px] cursor-pointer">
      <div className="relative w-[94px] h-[78px] rounded-[8px] overflow-hidden">
        <Image
          src={city.ImageUrlPath || '/images/Tristar-table.png'}
          layout="fill"
          alt={city.City}
        />
      </div>
      <p className="text-[13px] text-[#212121] font-normal ">{city.City}</p>
    </div>
  );
};
